.login-section,
.signup-section {
  background: #2b2733;
  font-family: 'Source Sans Pro';
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.form-section {
  align-items: center;
  padding: 25px;
  width: 250px;
  background-color: white;
  border-radius: 20px;
}

.title {
  display: flex;
  justify-content: space-between;
  color: #2b2733;
  margin-bottom: 10px;
  font-family: 'Martel Sans';
  font-size: 22px;
}

.title img {
  width: 30px;
  height: 30px
}

.subtitle {
  font-family: 'Lato';
  text-align: center;
  color: #201f31ab;
  margin-top: 0px;
  margin-bottom: 20px
}

.form-section img {
  margin-left: -webkit-fill-available;
  width: inherit;
}

.form input {
  display: inline-block;
  padding: 0 18px;
  vertical-align: middle;
  font-family: 'Lato';
  margin-bottom: 12px;
  width: -webkit-fill-available;
  height: 34px;
  background-color: rgb(228, 230, 230);
  border-radius: 8px;
  border: none;
  outline: none;
}

.form input:focus {
  box-sizing: border-box;
  box-shadow: 0px 0px 5px 0px #2b2733;
  background: white;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -webkit-appearance: none;
}

.form input::placeholder {
  color: rgba(0, 0, 0, 0.75);
  /* color: #2b2733; */
}

.form button {
  width: 100%;
  height: 34px;
  font-family: 'Martel Sans';
  background-color: #2b2733;
  border: none;
  border-radius: 8px;
  color: white;
  font-weight: 600;
  outline: none;
  cursor: pointer;
}

.form button:hover {
  transform: scale(1.025);
  background-image: linear-gradient(to bottom right, #4d4ae8, #8375d3);
  box-shadow: -2px 4px 20px 0px #2b2733;
}

.error-message {
  font-size: 13px;
  margin-top: 9px;
  color: rgb(247, 73, 102);
}

/* Go to Signup & Back to Login in Access/Register Sections */

.signup,
.login {
  display: flex;
  justify-content: center;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
}

.helper-text {
  color: rgba(0, 0, 0, 0.75);
  font-size: 15px;
  padding: 1px 6px;
  font-family: 'Lato'
}

.signup-btn,
.login-btn {
  font-family: 'Lato';
  font-size: 15px;
  text-decoration: underline;
  background: none;
  border: none;
  outline: none;
}

.signup-btn a, .login-btn a {
  color: #2b2733;
  text-decoration: none;
}

.signup-btn:hover,
.login-btn:hover {
  cursor: pointer;
  transform: scale(1.1);
  text-decoration: underline;
}

@media only screen and (max-width: 600px) {
  .form-section {
    margin-top: -60%
  }
}

@media(hover: hover) {
    .form button:hover {
      transform: scale(1.025);
      background-image: linear-gradient(to bottom right, #4d4ae8, #8375d3);
      box-shadow: -2px 4px 20px 0px #2b2733;
    }
}
